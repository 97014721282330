import http from './http'

export async function getTransactionStatsRequest(domain: string, startDate: Date, endDate: Date) {
    return (
        await http.get(`/dashboard/analytic/transaction-stat`, {
            headers: { DOMAIN: domain },
            params: {
                domain: domain,
                start_date: startDate.toISOString(),
                end_date: endDate.toISOString(),
            },
        })
    ).data
}

export async function getVisitorStatsRequest(domain: string, startDate: Date, endDate: Date) {
    return (
        await http.get(`/dashboard/analytic/page-view?granularity=minutes`, {
            headers: { DOMAIN: domain },
            params: {
                domain: domain,
                start_date: startDate.toISOString(),
                end_date: endDate.toISOString(),
            },
        })
    ).data
}

export async function getTransactionRequest(domain: string, startDate: Date, endDate: Date) {
    return (
        await http.get(`/dashboard/analytic/transaction`, {
            headers: { DOMAIN: domain },
            params: {
                domain: domain,
                start_date: startDate.toISOString(),
                end_date: endDate.toISOString(),
            },
        })
    ).data
}

export async function importTransactionRequest(domain: string, file: File) {
    const formData = new FormData()
    formData.append('domain', domain)
    formData.append('file', file)

    return await http.post(`/dashboard/analytic/import-excel`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    })
}
