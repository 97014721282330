/*
 * Author: dizhong zhu
 * Date: 16/05/2023
 */

import React, { Component, useEffect, useState } from 'react'
import { Divider } from 'widgets/UtilWidgets'
import { Button, Card, Col, Container, Form, Row, Table } from 'react-bootstrap'
import { Pencil, Trash } from 'react-bootstrap-icons'
import ReactAce from 'react-ace'
import { DisplayAlert } from 'widgets/DisplayAlert'
import { SizeChartT } from 'store/sizes'

interface BodyPartT {
    name: string
    isEditing: boolean
}

interface CombinedSizeT {
    header: string
    measurement: number
}

interface DispDataT {
    body_parts: BodyPartT[]
    size_headers: string[]
    measurements: number[][]
}

interface ParsedDispDataT {
    name: string
    gender: string
    data: DispDataT[]
}

function parse_disp_measures(measures: SizeChartT): ParsedDispDataT {
    const body_parts: { [key: string]: BodyPartT } = {}
    const size_headers: { [key: string]: string[] } = {}
    const measurements: { [key: string]: number[] } = {}

    Object.entries(measures.sizes).forEach(([part, sizes]) => {
        body_parts[part] = { name: part, isEditing: false }

        // Create a combined array of headers and measurements
        const combinedSizes: CombinedSizeT[] = []
        Object.entries(sizes).forEach(([size, value]) => {
            combinedSizes.push({ header: size, measurement: value[0] })
        })
        // Sort the combined array by the measurements
        combinedSizes.sort((a, b) => a.measurement - b.measurement)

        size_headers[part] = combinedSizes.map((sizeObj) => sizeObj.header)
        measurements[part] = combinedSizes.map((sizeObj) => sizeObj.measurement)
    })

    const disp_data: DispDataT[] = []
    // Compare each size_headers set
    for (const part in size_headers) {
        const currentHeaders = [...size_headers[part]]
        // check if there is a matching header in disp_data
        const matchingData = disp_data.find((data) => {
            const dataHeaders = [...data.size_headers]
            // compare if both arrays have same elements
            return dataHeaders.length === currentHeaders.length && dataHeaders.every((value, index) => value === currentHeaders[index])
        })

        if (matchingData) {
            matchingData.body_parts.push(body_parts[part])
            matchingData.measurements.push(measurements[part])
        } else {
            disp_data.push({
                body_parts: [body_parts[part]],
                size_headers: currentHeaders,
                measurements: [measurements[part]],
            })
        }
    }

    return {
        name: measures.name,
        gender: measures.gender,
        data: disp_data,
    }
}

interface BoardSizeChartProps {
    domain: string
    sizeCharts: SizeChartT[]
    handleAddSizeChart: (domain: string, data: any) => void
    handleUpdateSizeChart: (domain: string, data: any) => void
    handleDeleteSizeChart: (domain: string, data: any) => void
}

interface BoardSizeChartState {
    sizeChart: SizeChartT[]
    dispSizeChart: ParsedDispDataT[]
}

class BoardSizeChart extends Component<BoardSizeChartProps, BoardSizeChartState> {
    constructor(props: BoardSizeChartProps) {
        super(props)
        this.state = {
            sizeChart: this.props.sizeCharts,
            dispSizeChart: [],
        }
    }

    componentDidMount() {
        this.setState({
            sizeChart: this.props.sizeCharts,
            dispSizeChart: this.props.sizeCharts.map((chart) => parse_disp_measures(chart)),
        })
    }

    componentDidUpdate(prevProps: BoardSizeChartProps, prevState: BoardSizeChartState) {
        if (prevProps.sizeCharts !== this.props.sizeCharts) {
            // Update sizeChart state when props change
            this.updateSizeChartState(this.props.sizeCharts)
        }
    }

    updateSizeChartState = (sizeChart: SizeChartT[]) => {
        this.setState({
            sizeChart: sizeChart,
            dispSizeChart: sizeChart.map((chart) => parse_disp_measures(chart)),
        })
    }

    handleSizeChartSave = (json: SizeChartT, index: number) => {
        const updatedSizeChart = [...this.state.sizeChart]
        const { handleAddSizeChart, handleUpdateSizeChart } = this.props
        if (!json.uuid || json.uuid === '') {
            updatedSizeChart[index] = json
            handleAddSizeChart(this.props.domain, [json])
        } else {
            json.uuid = updatedSizeChart[index].uuid
            updatedSizeChart[index] = json
            handleUpdateSizeChart(this.props.domain, [json])
        }

        this.updateSizeChartState(updatedSizeChart)
    }

    handleSizeChartDelete = (index: number) => {
        const confirmed = window.confirm('Are you sure you want to delete this size chart?')

        const { handleDeleteSizeChart } = this.props
        if (confirmed) {
            const updatedSizeChart = [...this.state.sizeChart]
            const deleteItem = updatedSizeChart[index]
            updatedSizeChart.splice(index, 1)

            this.updateSizeChartState(updatedSizeChart)
            handleDeleteSizeChart(this.props.domain, deleteItem)
        }
    }

    handleSizeChartAdd = () => {
        const newSizeChart: SizeChartT = {
            name: '',
            gender: '',
            sizes: {},
        }

        const updatedSizeChart = Array.isArray(this.state.sizeChart) ? [...this.state.sizeChart, newSizeChart] : [newSizeChart]

        this.updateSizeChartState(updatedSizeChart)
    }

    render() {
        return (
            <React.Fragment>
                {this.state.dispSizeChart.map((chart, index) => (
                    <React.Fragment key={index}>
                        <CompDispSizechart
                            sizeChart={chart}
                            sizeChartJson={JSON.stringify(this.state.sizeChart[index], null, 2)}
                            onSave={(e) => this.handleSizeChartSave(e, index)}
                            onDelete={() => this.handleSizeChartDelete(index)}
                        />
                        <Divider className="my-4" />
                    </React.Fragment>
                ))}
                <Button className="bg-blue-600" onClick={this.handleSizeChartAdd}>
                    Add Size Chart
                </Button>
            </React.Fragment>
        )
    }
}

interface CompDispSizechartProps {
    sizeChart: ParsedDispDataT
    sizeChartJson: string
    onSave: (e: SizeChartT) => void
    onDelete: () => void
}

function CompDispSizechart({ sizeChart, sizeChartJson, onSave, onDelete }: CompDispSizechartProps) {
    const [isEditing, setIsEditing] = useState(false)
    const [editedSizeChartJson, setEditedSizeChartJson] = useState(sizeChartJson)

    useEffect(() => {
        // If the size chart is empty, trigger edit mode
        if (!sizeChart.name) {
            setIsEditing(true)
        }
    }, [sizeChart])

    const handleEdit = () => {
        setIsEditing(!isEditing)
    }

    const handleSave = () => {
        try {
            const parsedJson = JSON.parse(editedSizeChartJson)
            onSave(parsedJson)
            setIsEditing(false)
        } catch (e) {
            DisplayAlert('Invalid JSON format: ' + e)
        }
    }

    return (
        <Card>
            <Card.Header className="d-flex justify-content-between">
                <h5>{sizeChart.name}</h5>
                <div>
                    <Button variant="outline-danger" onClick={onDelete}>
                        <Trash />
                    </Button>
                    <Button variant="outline-secondary" onClick={handleEdit}>
                        <Pencil />
                    </Button>
                </div>
            </Card.Header>
            <Card.Body>
                {!isEditing ? (
                    <Container>
                        {sizeChart.data.map((data, index) => (
                            <Row key={index}>
                                <Col md={2}>
                                    <BodyPart chartName="body part" bodyParts={data.body_parts} />
                                </Col>

                                <Col md={10}>
                                    <SizeChartEditor sizeHeaders={data.size_headers} measurements={data.measurements} />
                                </Col>
                            </Row>
                        ))}
                    </Container>
                ) : (
                    <Form.Group className="mb-3" controlId="widget_config">
                        <ReactAce
                            mode="json"
                            theme="github"
                            value={editedSizeChartJson}
                            onChange={setEditedSizeChartJson}
                            style={{ width: '100%' }}
                            setOptions={{
                                showLineNumbers: true,
                                tabSize: 2,
                            }}
                        />
                        <Button className="bg-blue-600" onClick={handleSave}>
                            Save
                        </Button>
                    </Form.Group>
                )}
            </Card.Body>
        </Card>
    )
}

interface BodyPartProps {
    chartName: string
    bodyParts: BodyPartT[]
}

const BodyPart = ({ chartName, bodyParts }: BodyPartProps) => {
    return (
        <React.Fragment>
            <Table striped>
                <thead>
                    <tr>
                        <th className={'size-chart-cell'}>{chartName}</th>
                    </tr>
                </thead>
                <tbody>
                    {bodyParts?.map((bodyPart, index) => (
                        <tr key={index} className={'size-chart-cell'}>
                            <td>{bodyPart.name}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </React.Fragment>
    )
}

interface SizeChartEditorProps {
    sizeHeaders: string[]
    measurements: number[][]
}

const SizeChartEditor = ({ sizeHeaders, measurements }: SizeChartEditorProps) => {
    return (
        <React.Fragment>
            <div className="size-chart-container">
                <Table striped className="size-chart-table">
                    <thead>
                        <tr>
                            {sizeHeaders.map((header, index) => (
                                <th key={index} className={'size-chart-cell'}>
                                    {header}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {Array.isArray(measurements) &&
                            measurements.map((row, rowIndex) => (
                                <tr key={rowIndex} className="size-chart-table-row">
                                    {row.map((measurement, colIndex) => (
                                        <td className="size-chart-cell" key={colIndex}>
                                            {measurement}
                                        </td>
                                    ))}
                                </tr>
                            ))}
                    </tbody>
                </Table>
            </div>
        </React.Fragment>
    )
}
export { BoardSizeChart }
