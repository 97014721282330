/*
 * Author: dizhong zhu
 * Date: 10/12/2024
 */

import * as React from 'react'

import {
    Chart,
    ChartTitle,
    ChartSeries,
    ChartSeriesItem,
    ChartCategoryAxis,
    ChartCategoryAxisTitle,
    ChartCategoryAxisItem,
    ChartValueAxis,
    ChartValueAxisItem,
    ChartTooltip,
} from '@progress/kendo-react-charts'
import { Component } from 'react'
import { truncate, orderBy, max } from 'lodash'

const CustomTooltip = ({ point }: any) => {
    if (!point) return null

    // Get the full category name from the original categories array
    const fullName = point.category
    const seriesName = point.series.name
    const value = point.value

    return (
        <div className="p-2">
            {/*<div className="font-bold mb-1">{fullName}</div>*/}
            <div>
                {seriesName}: {value}
            </div>
        </div>
    )
}

interface props {
    clothStat: any
}

interface state {
    categories: Array<{
        full: string
        truncated: string
        index: number
    }>
    series: {
        name: string
        data: number[]
    }[]
    currentPage: number
    itemsPerPage: number
    totalPages: number
    maxCheckout: number
    sortBy: 'refundCountRate' | 'refundCount' | 'checkoutCount'
    sortOrder: 'asc' | 'desc'
}

export class ClothStatChart extends Component<props, state> {
    constructor(props: props) {
        super(props)

        const itemsPerPage = 5
        const allCategories = Object.keys(props.clothStat || {})
        const totalPages = Math.ceil(allCategories.length / itemsPerPage)

        this.state = {
            categories: [],
            series: [],
            currentPage: 1,
            itemsPerPage,
            totalPages,
            sortBy: 'checkoutCount',
            sortOrder: 'desc',
            maxCheckout: 0,
        }
    }

    componentDidMount() {
        this.initializeData()
    }

    componentDidUpdate(prevProps: props) {
        if (prevProps.clothStat !== this.props.clothStat) {
            this.initializeData()
        }
    }

    initializeData = () => {
        const { clothStat } = this.props
        if (!clothStat) return

        const allCategories = Object.keys(clothStat)
        const maxCheckout = max(allCategories.map((cat) => clothStat[cat]?.checkoutCount?.false || 0)) || 0

        this.setState(
            {
                maxCheckout,
                totalPages: Math.ceil(allCategories.length / this.state.itemsPerPage),
            },
            () => {
                this.updateChartData()
            }
        )
    }

    updateChartData = () => {
        const { clothStat } = this.props
        if (!clothStat) return

        const { currentPage, itemsPerPage, sortBy, sortOrder } = this.state

        // Calculate slice indices for pagination
        const startIndex = (currentPage - 1) * itemsPerPage
        const endIndex = startIndex + itemsPerPage

        // Get categories for current page and sort them
        const allCategories = Object.keys(clothStat)
        const sortedCategories = orderBy(allCategories, [(cat) => clothStat[cat][sortBy]?.false || 0], [sortOrder])
        const paginatedCategories = sortedCategories.slice(startIndex, endIndex)

        // Create array of category objects with both full and truncated names
        const categories = paginatedCategories.map((cat, index) => {
            const stats = clothStat[cat]

            // Calculate refund rates for usage and non-usage
            const usageRefundRate = stats?.refundCountRate?.true ? `${(stats.refundCountRate.true * 100).toFixed(1)}%` : '0%'

            const nonUsageRefundRate = stats?.refundCountRate?.false ? `${(stats.refundCountRate.false * 100).toFixed(1)}%` : '0%'

            // Append refund rates to category name
            const fullName = `${cat} \n Refund Rates(Usage: ${usageRefundRate}, Non-Usage: ${nonUsageRefundRate})`

            return {
                full: fullName,
                truncated: truncate(cat, { length: 20 }),
                index,
            }
        })

        // Update series data for current page
        const series = [
            {
                name: 'Usage Checkouts',
                data: paginatedCategories.map((cat) => clothStat[cat]?.checkoutCount?.true || 0),
            },
            {
                name: 'Usage Refunds',
                data: paginatedCategories.map((cat) => clothStat[cat]?.refundCount?.true || 0),
            },
            {
                name: 'Non-Usage Checkouts',
                data: paginatedCategories.map((cat) => clothStat[cat]?.checkoutCount?.false || 0),
            },
            {
                name: 'Non-Usage Refunds',
                data: paginatedCategories.map((cat) => clothStat[cat]?.refundCount?.false || 0),
            },
        ]

        this.setState({
            categories,
            series,
        })
    }

    handlePageChange = (newPage: number) => {
        this.setState({ currentPage: newPage }, () => {
            this.updateChartData()
        })
    }

    handleSortChange = (sortBy: 'refundCountRate' | 'refundCount' | 'checkoutCount', sortOrder: 'asc' | 'desc') => {
        this.setState({ sortBy, sortOrder, currentPage: 1 }, () => {
            this.updateChartData()
        })
    }

    render() {
        const { categories, series, currentPage, totalPages, sortBy, sortOrder, maxCheckout } = this.state

        return (
            <div className="flex flex-col items-center w-full">
                <div className="flex items-center justify-end mb-4">
                    <label htmlFor="sort-by" className="mr-2">
                        Sort by:
                    </label>
                    <select
                        id="sort-by"
                        value={sortBy}
                        onChange={(e) => this.handleSortChange(e.target.value as any, sortOrder)}
                        className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md"
                    >
                        <option value="refundCountRate">Refund Rate</option>
                        <option value="refundCount">Refunds</option>
                        <option value="checkoutCount">Checkouts</option>
                    </select>
                    <select
                        value={sortOrder}
                        onChange={(e) => this.handleSortChange(sortBy, e.target.value as any)}
                        className="ml-2 px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md"
                    >
                        <option value="asc">Ascending</option>
                        <option value="desc">Descending</option>
                    </select>
                </div>
                <Chart className="h-96 w-full">
                    <ChartTitle text="Product Stats" />
                    <ChartCategoryAxis>
                        <ChartCategoryAxisItem
                            categories={categories.map((cat) => cat.full)}
                            labels={{
                                padding: { left: 0, right: 0 },
                                margin: { top: 20 },
                            }}
                            max={100}
                        >
                            {/*<ChartCategoryAxisTitle text="Product Name" />*/}
                        </ChartCategoryAxisItem>
                    </ChartCategoryAxis>
                    {/*<ChartValueAxis>*/}
                    {/*    <ChartValueAxisItem*/}
                    {/*        max={maxCheckout}*/}
                    {/*    />*/}
                    {/*</ChartValueAxis>*/}
                    <ChartSeries>
                        {series.map((item, idx) => (
                            <ChartSeriesItem key={idx} type="bar" data={item.data} name={item.name} gap={2} spacing={0.25}></ChartSeriesItem>
                        ))}
                    </ChartSeries>
                    <ChartTooltip render={CustomTooltip} />
                </Chart>

                <div className="flex items-center gap-2 mt-4">
                    <button
                        onClick={() => this.handlePageChange(currentPage - 1)}
                        disabled={currentPage === 1}
                        className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 disabled:opacity-50"
                    >
                        Previous
                    </button>
                    <span className="text-sm text-gray-700">
                        Page {currentPage} of {totalPages}
                    </span>
                    <button
                        onClick={() => this.handlePageChange(currentPage + 1)}
                        disabled={currentPage === totalPages}
                        className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 disabled:opacity-50"
                    >
                        Next
                    </button>
                </div>
            </div>
        )
    }
}
