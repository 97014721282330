import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
    addClothItemRequest,
    addSizeChartRequest,
    deleteClothItemRequest,
    deleteSizeChartRequest,
    getClothItemRequest,
    getSizeChartRequest,
    updateClothItemRequest,
    updateSizeChartRequest,
} from 'services/sizes'
import { RootState } from 'store'
import toast from 'react-hot-toast'

/************************************************************************************************
 *
 *                                Types and Interfaces
 *
 ************************************************************************************************/
export interface SizeChartT {
    uuid?: string
    name: string
    gender: string
    sizes: {
        [key: string]: {
            [key: string]: [number]
        }
    }
}

export interface ClothItemT {
    uuid: string
    name: string
    gender: 'male' | 'female' | 'nonBinary'
    disp_measure: string
    size_chart: string
    image_url: string
    image_type: string
}

interface SizeState {
    sizeCharts: SizeChartT[]
    clothItems: ClothItemT[]
    isLoadingSizeChart: boolean
    isLoadingClothItem: boolean
    sizeChartErrors: any[]
    clothItemErrors: any[]
}

interface ListSizeChartResponseT {
    data: SizeChartT[]
    error: any[]
    message: string
    status: boolean
}

interface ListClothItemResponseT {
    data: ClothItemT[]
    error: any[]
    message: string
    status: boolean
}

export const initialState: SizeState = {
    sizeCharts: [],
    clothItems: [],
    isLoadingSizeChart: true,
    isLoadingClothItem: true,
    sizeChartErrors: [],
    clothItemErrors: [],
}

/************************************************************************************************
 *
 *                                          Functions
 *
 ************************************************************************************************/

export const getSizeCharts = createAsyncThunk<SizeChartT[], string>('size/size_chart_get', async (domain: string, { dispatch }) => {
    dispatch(resetSizeChart())

    // Clear the size chart

    return getSizeChartRequest(domain)
        .then((res: ListSizeChartResponseT) => {
            dispatch(setSizeCharts(res.data))
            return res.data
        })
        .catch((err: any) => {
            dispatch(setSizeChartError([err.response.data.message]))
        })
        .finally(() => dispatch(setIsLoadingSizeChart(false))) as Promise<SizeChartT[]>
})

export const addSizeChart = createAsyncThunk<any, any>('size/size_chart_add', async (input: any, { dispatch }) => {
    dispatch(setIsLoadingSizeChart(true))

    const { domain, data } = input
    return addSizeChartRequest(domain, data)
        .then((response: any) => {
            toast.success('Size chart added successfully')
            return response.data
        })
        .catch((err: any) => {
            dispatch(setSizeChartError([err.response.data.message]))
        })
        .finally()
})

export const updateSizeChart = createAsyncThunk<any, any>('size/size_chart_update', async (input: any, { dispatch }) => {
    const { domain, data } = input
    return updateSizeChartRequest(domain, data)
        .then((response: any) => {
            toast.success('Size chart updated successfully')
            return response.data
        })
        .catch((err: any) => {
            dispatch(setSizeChartError([err.response.data.message]))
        })
        .finally()
})

export const deleteSizeChart = createAsyncThunk<any, any>('size/size_chart_delete', async (input: any, { dispatch }) => {
    dispatch(setIsLoadingSizeChart(true))

    const { domain, data } = input
    return deleteSizeChartRequest(domain, data)
        .then((response: any) => {
            toast.success('Size chart deleted successfully')
            return response.data
        })
        .catch((err: any) => {
            dispatch(setSizeChartError([err.response.data.message]))
        })
        .finally()
})

export const getClothItems = createAsyncThunk<ClothItemT[], string>('size/cloth_item_get', async (domain: string, { dispatch }) => {
    dispatch(resetClothItem())

    return getClothItemRequest(domain)
        .then((res: ListClothItemResponseT) => {
            dispatch(setClothItems(res.data))
            return res.data
        })
        .catch((err: any) => {
            dispatch(setClothItemError([err.response.data.message]))
        })
        .finally(() => dispatch(setIsLoadingClothItem(false))) as Promise<ClothItemT[]>
})

export const addClothItem = createAsyncThunk<any, any>('size/cloth_item_add', async (input: any, { dispatch }) => {
    dispatch(setIsLoadingClothItem(true))

    const { domain, data } = input
    return addClothItemRequest(domain, data)
        .then((response: any) => {
            toast.success('Cloth item added successfully')
            return response.data
        })
        .catch((err: any) => {
            toast.error('Cloth item added failed, reason: ' + err.response.data.message)
            dispatch(setClothItemError([err.response.data.message]))
        })
        .finally(() => dispatch(setIsLoadingClothItem(false)))
})

export const updateClothItem = createAsyncThunk<any, any>('size/cloth_item_update', async (input: any, { dispatch }) => {
    dispatch(setIsLoadingClothItem(true))

    const { domain, data } = input
    return updateClothItemRequest(domain, data)
        .then((response: any) => {
            toast.success('Cloth item updated successfully')
            return response.data
        })
        .catch((err: any) => {
            toast.error('Cloth item updated failed, reason: ' + err.response.data.message)
            dispatch(setClothItemError([err.response.data.message]))
        })
        .finally(() => dispatch(setIsLoadingClothItem(false)))
})

export const deleteClothItem = createAsyncThunk<any, any>('size/cloth_item_delete', async (input: any, { dispatch }) => {
    dispatch(setIsLoadingClothItem(true))

    const { domain, data } = input
    return deleteClothItemRequest(domain, data)
        .then((response: any) => {
            toast.success('Cloth item deleted successfully')
            return response.data
        })
        .catch((err: any) => {
            dispatch(setClothItemError([err.response.data.message]))
        })
        .finally(() => dispatch(setIsLoadingClothItem(false)))
})

export const sizeSlice = createSlice({
    name: 'size',
    initialState,
    reducers: {
        setSizeCharts: (state, { payload }: PayloadAction<SizeChartT[]>) => {
            state.sizeCharts = payload
        },
        setSizeChartError: (state, { payload }: PayloadAction<any>) => {
            state.sizeChartErrors = payload
        },
        setIsLoadingSizeChart: (state, { payload }: PayloadAction<boolean>) => {
            state.isLoadingSizeChart = payload
        },
        resetSizeChart: (state) => {
            state.isLoadingSizeChart = true
            state.sizeChartErrors = []
            state.sizeCharts = []
        },
        setClothItems: (state, { payload }: PayloadAction<ClothItemT[]>) => {
            state.clothItems = payload
        },
        setIsLoadingClothItem: (state, { payload }: PayloadAction<boolean>) => {
            state.isLoadingClothItem = payload
        },
        setClothItemError: (state, { payload }: PayloadAction<any>) => {
            state.clothItemErrors = payload
        },
        resetClothItem: (state) => {
            state.isLoadingClothItem = true
            state.clothItemErrors = []
            state.clothItems = []
        },
    },
})

export const { setSizeCharts, setIsLoadingSizeChart, setSizeChartError, resetSizeChart, setClothItems, setIsLoadingClothItem, setClothItemError, resetClothItem } = sizeSlice.actions

export const sizeSelector = (state: RootState) => state.size

export default sizeSlice.reducer
