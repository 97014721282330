import http from './http'
import { dataURLtoFileBlob } from '../utils/data_process'

export async function getSizeChartRequest(domain: string) {
    return (
        await http.get(`/dashboard/sizechart`, {
            params: { domain: domain },
        })
    ).data
}

export async function addSizeChartRequest(domain: string, data: any) {
    return (
        await http.post(`/dashboard/sizechart`, data, {
            params: { domain: domain },
        })
    ).data
}

export async function updateSizeChartRequest(domain: string, data: any) {
    return (
        await http.patch(`/dashboard/sizechart`, data, {
            params: { domain: domain },
        })
    ).data
}

export async function deleteSizeChartRequest(domain: string, data: any) {
    return (
        await http.delete(`/dashboard/sizechart`, {
            data: {
                names: [data.name],
            },
            params: {
                domain: domain,
            },
        })
    ).data
}

export async function getClothItemRequest(domain: string) {
    return (
        await http.get(`/dashboard/clothitem`, {
            params: { domain: domain },
        })
    ).data
}

export async function addClothItemRequest(domain: string, data: any) {
    const formData = new FormData()
    if (data.image_blob) formData.append('image', dataURLtoFileBlob(data.image_blob), `${data.name}${data.image_type}`)
    formData.append('name', data.name)
    formData.append('uuid', data.uuid)
    formData.append('gender', data.gender)
    formData.append('size_chart', data.size_chart)
    formData.append('disp_measure', data.disp_measure)

    return (
        await http.post('/dashboard/clothitem', formData, {
            params: { domain },
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
    ).data
}

export async function updateClothItemRequest(domain: string, data: any) {
    const formData = new FormData()
    if (data.image_blob) formData.append('image', dataURLtoFileBlob(data.image_blob), `${data.name}${data.image_type}`)
    formData.append('name', data.name)
    formData.append('uuid', data.uuid)
    formData.append('gender', data.gender)
    formData.append('size_chart', data.size_chart)
    formData.append('disp_measure', data.disp_measure)

    return (
        await http.patch('/dashboard/clothitem', formData, {
            params: { domain },
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
    ).data
}

export async function deleteClothItemRequest(domain: string, uuids: any) {
    return (
        await http.delete('/dashboard/clothitem', {
            data: {
                uuids: uuids,
            },
            params: {
                domain,
            },
        })
    ).data
}
