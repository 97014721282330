/*
 * Author: dizhong zhu
 * Date: 27/04/2023
 */

// TenSettingSizeChart.js

import React, { useEffect } from 'react'
import { Card, Container } from 'react-bootstrap'
import { Divider } from 'widgets/UtilWidgets'
import { BoardClothItems } from './BoardClothItems'
import { BoardSizeChart } from './BoardSizeChart'
import { useAppDispatch, useAppSelector } from '../../../../store'
import { addClothItem, addSizeChart, deleteClothItem, deleteSizeChart, getClothItems, getSizeCharts, updateClothItem, updateSizeChart } from '../../../../store/sizes'

interface props {
    domain: string
}

const TenSettingSizeChart: React.FC<props> = ({ domain }) => {
    const dispatch = useAppDispatch()
    const { sizeCharts, clothItems, isLoadingSizeChart, isLoadingClothItem } = useAppSelector((state) => state.size)

    useEffect(() => {
        dispatch(getSizeCharts(domain))
        dispatch(getClothItems(domain))
    }, [domain])

    const handleAddSizeChart = (domain: string, data: any) => {
        dispatch(addSizeChart({ domain: domain, data: data }))
        dispatch(getSizeCharts(domain))
    }

    const handleUpdateSizeChart = (domain: string, data: any) => {
        dispatch(updateSizeChart({ domain: domain, data: data }))
        dispatch(getSizeCharts(domain))
    }

    const handleDeleteSizeChart = (domain: string, data: any) => {
        dispatch(deleteSizeChart({ domain: domain, data: data }))
        dispatch(getSizeCharts(domain))
    }

    const handleAddClothItem = (domain: string, data: any) => {
        dispatch(addClothItem({ domain, data: data }))
        dispatch(getClothItems(domain))
    }

    const handleUpdateClothItem = (domain: string, data: any) => {
        dispatch(updateClothItem({ domain, data: data }))
        dispatch(getClothItems(domain))
    }

    const handleDeleteClothItem = (domain: string, data: any) => {
        dispatch(deleteClothItem({ domain, data: data }))
        dispatch(getClothItems(domain))
    }

    return (
        <Container fluid>
            <Card className="setting-boarder mb-4">
                <Card.Body>
                    <h4>Configuration of size chart</h4>
                    <Divider className="my-2" />
                    {!isLoadingSizeChart && (
                        <BoardSizeChart
                            domain={domain}
                            sizeCharts={sizeCharts}
                            handleAddSizeChart={handleAddSizeChart}
                            handleUpdateSizeChart={handleUpdateSizeChart}
                            handleDeleteSizeChart={handleDeleteSizeChart}
                        />
                    )}

                    <h4>Configuration of cloth itmes</h4>
                    <Divider className="my-2" />
                    {!isLoadingClothItem && (
                        <BoardClothItems
                            domain={domain}
                            clothItems={clothItems}
                            sizeCharts={sizeCharts}
                            handleAddClothItem={handleAddClothItem}
                            handleUpdateClothItem={handleUpdateClothItem}
                            handleDeleteClothItem={handleDeleteClothItem}
                        />
                    )}
                </Card.Body>
            </Card>
        </Container>
    )
}

export { TenSettingSizeChart }
