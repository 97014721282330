/*
 * Author: dizhong zhu
 * Date: 03/05/2023
 */

import { Component, useEffect, useState } from 'react'
import { Card, ButtonGroup, ToggleButton, ListGroup, Button, Spinner } from 'react-bootstrap'
import { ClothItemCell } from './ClotItemCell'
import { DisplayAlert, DisplaySuccess } from 'widgets/DisplayAlert'
import { v4 as uuidv4 } from 'uuid'
import { fetchImage } from 'utils/data_process'
import { ClothItemT, SizeChartT } from 'store/sizes'
import toast from 'react-hot-toast'

interface ClothItemUIT extends ClothItemT {
    image_blob: any
    isEditing: boolean
}

interface ParsedClothItemT {
    male: ClothItemUIT[]
    female: ClothItemUIT[]
    nonBinary: ClothItemUIT[]
}

const isDuplicateName = (clothes: ParsedClothItemT, gender: 'male' | 'female' | 'nonBinary', index: number, value: string) => {
    const existingIndex = clothes[gender].findIndex((item, idx) => item.name === value && idx !== index)
    return existingIndex !== -1
}

async function parse_cloth_items(cloth_items: ClothItemT[], setIsLoading: any) {
    const parse_cloth_items: ParsedClothItemT = {
        male: [],
        female: [],
        nonBinary: [],
    }

    if (!cloth_items) return parse_cloth_items

    setIsLoading(true)

    for (let i = 0; i < cloth_items.length; i++) {
        const cloth: ClothItemUIT = {
            ...cloth_items[i], // Copy all fields from base class
            image_blob: '', // Initialize new fields
            isEditing: false,
        }
        const image = await fetchImage(cloth_items[i].image_url)

        let processedImageBlob = ''
        if (image.type === '.svg') {
            processedImageBlob = image.dataUrl.replace('data:application/octet-stream', 'data:image/svg+xml')
        } else {
            processedImageBlob = image.dataUrl.replace('data:application/octet-stream', `data:${image.type}`)
        }
        cloth.image_blob = processedImageBlob
        cloth.image_type = image.type

        if (cloth_items[i].gender === 'male') {
            parse_cloth_items.male.push(cloth)
        } else if (cloth_items[i].gender === 'female') {
            parse_cloth_items.female.push(cloth)
        } else {
            parse_cloth_items.nonBinary.push(cloth)
        }
    }

    setIsLoading(false)
    return parse_cloth_items
}

interface ChangesT {
    [key: string]: {
        type: string
        item: ClothItemUIT
    }
}

interface BoardClothItemsProps {
    domain: string
    clothItems: ClothItemT[]
    sizeCharts: SizeChartT[]
    handleAddClothItem: (domain: string, data: any) => void
    handleUpdateClothItem: (domain: string, data: any) => void
    handleDeleteClothItem: (domain: string, data: any) => void
}

interface BoardClothItemsState {
    isLoading: boolean
    gender: 'male' | 'female' | 'nonBinary'
    clothItems: ParsedClothItemT
    changes: ChangesT
    createdItems: string[]
}

class BoardClothItems extends Component<BoardClothItemsProps, BoardClothItemsState> {
    constructor(props: BoardClothItemsProps) {
        super(props)
        this.state = {
            isLoading: false,
            gender: 'male',
            clothItems: {
                male: [],
                female: [],
                nonBinary: [],
            },
            changes: {},
            createdItems: [],
        }
    }

    componentDidMount() {
        this.fetchAndProcessData()
    }

    componentDidUpdate(prevProps: BoardClothItemsProps) {
        if (prevProps.clothItems !== this.props.clothItems || prevProps.sizeCharts !== this.props.sizeCharts) {
            this.fetchAndProcessData()
        }
    }

    fetchAndProcessData = async () => {
        await parse_cloth_items(this.props.clothItems, (loading: boolean) => this.setState({ isLoading: loading })).then((data) => {
            this.setState({ clothItems: data })
        })
    }

    handleNameChange = (e: any, gender: 'male' | 'female' | 'nonBinary', index: number) => {
        const { value } = e.target
        const clothes = { ...this.state.clothItems }
        clothes[gender][index].name = value
        this.setState({ clothItems: clothes })
    }

    toggleNameEdit = (gender: 'male' | 'female' | 'nonBinary', index: number) => {
        const clothes = { ...this.state.clothItems }

        if (clothes[gender][index].isEditing) {
            if (isDuplicateName(clothes, gender, index, clothes[gender][index].name)) {
                toast.error('Item name already exists')
                return
            }

            this.setState((prevState) => ({
                changes: {
                    ...prevState.changes,
                    [clothes[gender][index].uuid]: {
                        type: 'update',
                        item: clothes[gender][index],
                    },
                },
            }))
        }
        clothes[gender][index].isEditing = !clothes[gender][index].isEditing
        this.setState({ clothItems: clothes })
    }

    handleDelete = (gender: 'male' | 'female' | 'nonBinary', index: number) => {
        const clothes = { ...this.state.clothItems }
        const item = clothes[gender][index]
        clothes[gender].splice(index, 1)

        this.setState((prevState) => ({
            clothItems: clothes,
            changes: {
                ...prevState.changes,
                [item.uuid]: { type: 'delete', item: item },
            },
        }))
    }

    handleImageUpload = (e: any, gender: 'male' | 'female' | 'nonBinary', index: number) => {
        const file = e.target.files[0]
        if (file) {
            const reader = new FileReader()
            reader.onloadend = () => {
                const clothes = { ...this.state.clothItems }
                clothes[gender][index].image_blob = reader.result
                const fileExtension = file.name.split('.').pop()
                clothes[gender][index].image_type = '.' + fileExtension

                this.setState((prevState) => ({
                    clothItems: clothes,
                    changes: {
                        ...prevState.changes,
                        [clothes[gender][index].uuid]: {
                            type: 'update',
                            item: clothes[gender][index],
                        },
                    },
                }))
            }
            reader.readAsDataURL(file)
        }
    }

    handleSizeChartChange = (selectId: number, gender: 'male' | 'female' | 'nonBinary', index: number) => {
        const clothes = { ...this.state.clothItems }
        clothes[gender][index].size_chart = this.props.sizeCharts[selectId]?.name

        this.setState((prevState) => ({
            clothItems: clothes,
            changes: {
                ...prevState.changes,
                [clothes[gender][index].uuid]: {
                    type: 'update',
                    item: clothes[gender][index],
                },
            },
        }))
    }

    handleAddItem = () => {
        const newItem: ClothItemUIT = {
            uuid: uuidv4(),
            name: 'New cloth',
            gender: this.state.gender,
            disp_measure: 'chest, waist',
            size_chart: '',
            image_blob: '',
            image_type: '',
            image_url: '',
            isEditing: true,
        }

        const clothes = { ...this.state.clothItems }
        clothes[this.state.gender].push(newItem)

        this.setState((prevState) => ({
            clothItems: clothes,
            createdItems: [...prevState.createdItems, newItem.uuid],
        }))
    }

    handleGenderChange = (gender: 'male' | 'female' | 'nonBinary') => {
        this.setState({ gender })
    }

    handleSave = async () => {
        const deleteItems = []
        const { domain, handleAddClothItem, handleUpdateClothItem, handleDeleteClothItem } = this.props
        const { changes, createdItems } = this.state

        for (const itemId in changes) {
            const change = changes[itemId]
            if (createdItems.includes(itemId)) {
                handleAddClothItem(domain, change.item)
            } else if (change.type === 'update') {
                handleUpdateClothItem(domain, change.item)
            } else if (change.type === 'delete') {
                deleteItems.push(itemId)
            }
        }

        if (deleteItems.length > 0) {
            handleDeleteClothItem(domain, deleteItems)
        }

        this.setState({
            changes: {},
            createdItems: [],
        })
    }

    render() {
        const { gender, isLoading, clothItems } = this.state
        const { sizeCharts } = this.props
        return (
            <Card>
                <Card.Header className="d-flex justify-content-between align-items-center">
                    <ButtonGroup>
                        {['male', 'female'].map((value) => (
                            <ToggleButton
                                key={value}
                                id={`toggle-button-${value}`}
                                type="radio"
                                variant="outline-primary"
                                name="gender"
                                value={value}
                                checked={gender === value}
                                onClick={() => this.handleGenderChange(value as 'male' | 'female')}
                            >
                                {value}
                            </ToggleButton>
                        ))}
                    </ButtonGroup>
                    <Button className="bg-blue-600" variant="primary" onClick={this.handleAddItem}>
                        Add Item
                    </Button>
                </Card.Header>
                {isLoading ? (
                    <Spinner />
                ) : (
                    <Card.Body>
                        <ListGroup>
                            {clothItems[gender].map((item, index) => (
                                <ListGroup.Item key={index}>
                                    <ClothItemCell
                                        item={item}
                                        sizeChartList={sizeCharts.map((chart) => chart.name)}
                                        placeholder=""
                                        onToggleEdit={() => this.toggleNameEdit(gender, index)}
                                        onChange={(e) => this.handleNameChange(e, gender, index)}
                                        onEnter={() => this.toggleNameEdit(gender, index)}
                                        onDelete={() => this.handleDelete(gender, index)}
                                        onImage={(e) => this.handleImageUpload(e, gender, index)}
                                        onSize={(e: any) => this.handleSizeChartChange(e, gender, index)}
                                    />
                                </ListGroup.Item>
                            ))}
                        </ListGroup>
                    </Card.Body>
                )}

                <Card.Footer>
                    <Button className="bg-blue-600" variant="primary" onClick={this.handleSave}>
                        Save
                    </Button>
                </Card.Footer>
            </Card>
        )
    }
}

export { BoardClothItems }
